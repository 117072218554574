import React from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import { renderSEO, ROUTES } from "@lesmills/gatsby-theme-common"

import ForgotPassword from "../../components/ForgotPassword"

type Props = {|
  location: Object,
  data: Object,
  pageContext: {
    lang: string,
    id: string,
  },
|}

const ForgotPasswordPage = (props: Props) => {
  const { pageContext, data, location } = props || {}

  const lang = pageContext.unPublishedLang || pageContext.lang
  if (!data) return null

  const forgotPasswordData = data.prismicForgotPasswordPage.data
  const layoutData = data.prismicLayout && data.prismicLayout.data

  return (
    <>
      {renderSEO(forgotPasswordData, ROUTES(lang).FORGOT_PASSWORD, lang)}
      <ForgotPassword
        data={forgotPasswordData}
        layoutData={layoutData}
        lang={lang}
        location={location}
        editPersonalDetailsTitle={
          data.prismicUpdatePersonalInformationPage.data.title
        }
      />
    </>
  )
}

export const query = graphql`
  query ForgotPasswordPage($lang: String) {
    prismicUpdatePersonalInformationPage(lang: { eq: $lang }) {
      data {
        title {
          text
        }
      }
    }
    prismicForgotPasswordPage(lang: { eq: $lang }) {
      data {
        user_not_found_exception {
          text
        }
        back_to_login {
          text
        }
        email_label {
          text
        }
        email_not_found {
          text
        }
        invalid_email {
          text
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        required_email {
          text
        }
        send_reset_code_button_label {
          text
        }
        subtitle {
          raw
        }
        title {
          text
        }
      }
    }
    prismicLayout(lang: { eq: $lang }) {
      data {
        les_mills_plus_logo_black {
          alt
          url
        }
      }
    }
  }
`

export default withPreview(ForgotPasswordPage)
