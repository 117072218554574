import React, { useState, useRef, useEffect, memo } from "react"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"
import { Link, navigate } from "gatsby"

// AWS Amplify
import Amplify, { Auth } from "aws-amplify"

import {
  Textbox,
  Button,
  FormWrapper,
  FormValidator,
  Icon,
  setLocalStorage,
  FORGOT_PWD_KEY,
  ROUTES,
  cognitoConfig,
  htmlSerializerUpdateStyle,
  linkResolver,
  getLocalStorage,
  USER_INFO_KEY,
  isEmpty,
  captureException,
  SENTRY_ACTIONS,
  ERRORS,
} from "@lesmills/gatsby-theme-common"

const client = () => Amplify.configure(cognitoConfig)

type Props = {|
  location: {
    state: Object,
  },
  data: Object,
  layoutData: Object,
  lang: string,
  editPersonalDetailsTitle: string,
|}

const ForgotPassword = ({
  data,
  layoutData,
  lang,
  editPersonalDetailsTitle,
  location,
}: Props) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [errors, setErrors] = useState({})
  const emailRef = useRef(null)
  const {
    back_to_login = {},
    email_label = {},
    invalid_email = {},
    required_email = {},
    send_reset_code_button_label = {},
    subtitle = {},
    title = {},
  } = data || {}

  const { les_mills_plus_logo_black } = layoutData || {}

  const isLoggedIn = !isEmpty(getLocalStorage(USER_INFO_KEY))

  const signinState = location.state || {}
  const signedLink = signinState.signedLink

  // Init AWS Amplify
  useEffect(() => {
    client()
  }, [])

  const getValue = current => current.value

  const handleDisableLink = e => {
    if (isProcessing) {
      e.preventDefault()
    }
    return
  }

  const handleSubmit = () => {
    const email = emailRef.current
      ? getValue(emailRef.current)
          .trim()
          .toLowerCase()
      : ""
    const error = FormValidator(["REQUIRED", "EMAIL"], email, {
      requiredError: required_email.text,
      invalidError: invalid_email.text,
    })

    setErrors({ email: error })

    if (!error) {
      setIsProcessing(true)
      // Integrate with API
      Auth.forgotPassword(email)
        .then(() => {
          setLocalStorage(FORGOT_PWD_KEY, { email: email })
          navigate(ROUTES(lang).RESET_PASSWORD)
        })
        .catch(err => {
          setErrors({
            email: data[ERRORS[err.code]]
              ? data[ERRORS[err.code]].text
              : err.message,
          })
          // Send error to sentry
          captureException({
            action: SENTRY_ACTIONS.FORGOT_PASSWORD,
            requestVariables: { email: email },
            ...err,
          })
        })
        .finally(() => setIsProcessing(false))
    }
  }

  const handleOnKeyPress = e => {
    if (e.which === 13) {
      e.preventDefault()
      handleSubmit()
    }
  }

  return (
    <FormWrapper
      logo={les_mills_plus_logo_black}
      title={title.text}
      classNames={{ wrapper: "md:h-auth-form-lg sign-in-wrapper" }}
      testId="forgot-pwd-form"
      lang={lang}
      loaded
      isLoggedIn={isLoggedIn}
    >
      <RichText
        render={subtitle.raw}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "form-description w-btn-lg md:text-2lg text-base md:w-login-description-lg text-gray-800 ml-auto mr-auto md:leading-7none leading-normal md:mb-45 md:mt-35 mt-28 mb-58 font-base-light"
        )}
      />
      <Textbox
        label={email_label.text}
        classNames={{
          textbox:
            "w-textbox-base h-textbox-base md:w-textbox-lg md:h-textbox-lg md:text-2lg",
          label: "md:text-2lg",
        }}
        error={errors.email}
        inputRef={emailRef}
        handleOnKeyPress={handleOnKeyPress}
        testId="forgot-pwd-email"
        id="email"
        disabled={isProcessing}
      />
      <Button
        handleOnClick={handleSubmit}
        className="btn btn-primary pt-3 pb-3 min-w-full sm:min-w-290 sm:px-30 ml-auto mr-auto md:mt-7 mb-46"
        disabled={isProcessing}
        submitting={isProcessing}
        testId="send-code-btn"
      >
        {send_reset_code_button_label.text}
      </Button>
      {/* https://lesmillsinternational.atlassian.net/browse/LA-820 */}
      {/* if logged in back to edit personal details else back to sign in */}
      <Link
        to={
          isLoggedIn
            ? ROUTES(lang).EDIT_PERSONAL_INFORMATION
            : signedLink
            ? ROUTES(lang).RESELLER_SIGN_IN
            : ROUTES(lang).SIGN_IN
        }
        state={signinState ? { ...signinState } : {}}
        className={`text-base md:text-2lg flex justify-center md:leading-7none leading-snug font-base-light block text-gray-800${
          isProcessing ? " disabled" : ""
        }`}
        onClick={handleDisableLink}
      >
        <Icon icon="black-back" type="medium" />
        <span data-cy="back-to-signin">
          {isLoggedIn ? editPersonalDetailsTitle.text : back_to_login.text}
        </span>
      </Link>
    </FormWrapper>
  )
}

export default memo(ForgotPassword, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
